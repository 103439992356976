<template>
    <div
        data-callback="handleCaptcha"
        class="g-recaptcha is-pulled-right"
        :data-sitekey="this.sitekey"
    />
</template>

<script>
import {eventBus} from "@master";

export default {
    props: {
        sitekey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            recaptcha: '',
            captchaElement: null,
        }
    },
    methods: {
        handleCaptcha(token) {
            this.$emit('input', token);
        }
    },
    mounted() {
        window.handleCaptcha = this.handleCaptcha;

        const script = document.createElement('script');
        script.src = 'https://www.google.com/recaptcha/enterprise.js';
        this.captchaElement = document.head.appendChild(script);

        eventBus.$on('clearRecaptcha', () => {
            if (window.grecaptcha) {
                grecaptcha?.enterprise?.reset?.();
            }
        })
    },
    beforeDestroy() {
        this.captchaElement.remove();
    }
}
</script>
